import '@/styles/globals.css';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import theme from '@/theme';
import { ChakraProvider } from '@chakra-ui/react';
import { AppContextProvider } from '@/components/structural/AppContextProvider';
import Main from '@/pages/_main';

export default function App(appProps: AppProps) {
  return (
    <>
      <Head>
        <title>{process.env.NEXT_PUBLIC_APP_NAME}</title>
        <meta name={'viewport'} content={'width=device-width, initial-scale=1'} />
        <link rel={'icon'} href={'/favicon.ico'} />

        <meta name="application-name" content={process.env.NEXT_PUBLIC_APP_NAME} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content={process.env.NEXT_PUBLIC_APP_NAME} />
        <meta name="description" content="Domestic Management" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-config" content="/icons/browserconfig.xml" />
        <meta name="msapplication-TileColor" content="#67C2BF" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#1A202D" />

        <link rel="apple-touch-icon" href="/logo.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/logo-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/logo-180x180.png" />
        <link rel="apple-touch-icon" sizes="167x167" href="/logo-167x167.png" />

        <link rel="icon" type="image/png" sizes="32x32" href="/logo-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/logo-64x64.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#5bbad5" />
        <link rel="shortcut icon" href="/favicon.ico" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://pandom.us" />
        <meta name="twitter:title" content={process.env.NEXT_PUBLIC_APP_NAME} />
        <meta name="twitter:description" content="Domestic Management" />
        <meta name="twitter:image" content="https://pandom.us/logo-192x192.png" />
        <meta name="twitter:creator" content="@masinamichele" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={process.env.NEXT_PUBLIC_APP_NAME} />
        <meta property="og:description" content="Domestic Management" />
        <meta property="og:site_name" content={process.env.NEXT_PUBLIC_APP_NAME} />
        <meta property="og:url" content="https://pandom.us" />
        <meta property="og:image" content="https://pandom.us/logo.png" />

        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <ChakraProvider theme={theme}>
        <AppContextProvider>
          <Main {...appProps} />
        </AppContextProvider>
      </ChakraProvider>
    </>
  );
}
