import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAuth } from '@/context/AuthContext';
import Loader from '@/components/structural/Loader';
import {
  collection,
  onSnapshot,
  serverTimestamp,
  query,
  orderBy,
  type Timestamp,
  getDocs,
  where,
  writeBatch,
  deleteDoc,
  doc,
  setDoc,
  type QueryDocumentSnapshot,
  FieldValue,
} from '@firebase/firestore';
import { firestore } from '@/firebase/client';
import { api, chunk, StorageKeys, unwrap } from '@/utils/client';
import { flushSync } from 'react-dom';
import { translate } from '@/context/TranslationContext';
import { useCustomTheme } from '@/context/CustomThemeContext';
import { User } from 'firebase/auth';
import { FaThLarge as WorkspaceIcon } from 'react-icons/fa';

export interface IWorkspace {
  id: string;
  name: string;
  icon?: string;
  created?: Timestamp | FieldValue;
  isDefault?: boolean;
  isShared?: boolean;
  isOwner?: boolean;
  owner: string;
  ownerName: string;
  users?: string[];
}

export class Workspace implements IWorkspace {
  id: string;
  name: string;
  icon?: string;
  created: Timestamp | FieldValue;
  isDefault: boolean;
  isShared: boolean;
  isOwner: boolean;
  owner: string;
  ownerName: string;
  users: string[];

  static readonly defaultWorkspaceName = 'Default';
  constructor(input: IWorkspace) {
    this.id = input.id;
    this.name = input.name;
    this.icon = input.icon;
    this.created = input.created ?? serverTimestamp();
    this.isDefault = input.isDefault ?? false;
    this.isShared = input.isShared ?? false;
    this.isOwner = input.isOwner ?? true;
    this.owner = input.owner;
    this.ownerName = input.ownerName;
    this.users = input.users ?? [];
  }

  static fromDocument(document: QueryDocumentSnapshot) {
    return new Workspace({ id: document.id, ...document.data() } as IWorkspace);
  }

  static default(id: string, user: User) {
    return new Workspace({
      id,
      name: this.defaultWorkspaceName,
      created: serverTimestamp(),
      isDefault: true,
      isShared: false,
      isOwner: true,
      owner: user.uid,
      ownerName: user.displayName!,
    });
  }

  toJson(target: 'firestore' | 'server') {
    const json = JSON.parse(JSON.stringify(this));
    if (target == 'firestore') {
      delete json.id;
      if (this.created instanceof FieldValue) {
        json.created = serverTimestamp();
      }
    }
    if (target == 'server') {
      delete json.created;
    }
    return json;
  }

  get canBeRenamed() {
    if (this.isDefault) return false;
    if (this.isShared && !this.isOwner) return false;
    return true;
  }

  get label() {
    return `${this.name} ${this.icon ?? ''}`;
  }
}

interface IWorkspacesContext {
  workspaces: Workspace[];
  myWorkspaces: Workspace[];
  participatedWorkspaces: Workspace[];
  currentWorkspace: Workspace;
  WorkspaceIcon: typeof WorkspaceIcon;
  createWorkspace: (name: string, users?: string[]) => Promise<any>;
  selectWorkspace: (id: string) => void;
  renameWorkspace: (id: string, name: string) => Promise<void>;
  deleteWorkspace: (id: string) => Promise<void>;
  leaveWorkspace: (id: string, owner: string) => Promise<void>;
  stopWorkspaceShare: (id: string) => Promise<void>;
  changeWorkspaceIcon: (id: string, icon: string | null) => Promise<void>;
  updateItemWorkspace: (itemPath: string, workspaceId: string) => Promise<void>;
}

const WorkspacesContext = createContext<IWorkspacesContext>({} as IWorkspacesContext);

export const useWorkspaces = () => useContext(WorkspacesContext);

export default function WorkspacesProvider({ children }: PropsWithChildren) {
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const [myWorkspaces, setMyWorkspaces] = useState<Workspace[]>([]);
  const [participatedWorkspaces, setParticipatedWorkspaces] = useState<Workspace[]>([]);
  const [currentWorkspace, setCurrentWorkspace] = useState<Workspace>();
  const [loading, setLoading] = useState(false);
  const { isLoggedIn, user } = useAuth();
  const collectionReference = useMemo(() => collection(firestore, `users/${user?.uid}/workspaces`), [user?.uid]);
  const isCreatingNew = useRef(false);
  const t = translate('WorkspacesProvider');
  const { toast } = useCustomTheme();

  useEffect(() => {
    if (!isLoggedIn) return;
    return onSnapshot(query(collectionReference, orderBy('created', 'asc')), (snap) => {
      if (!snap.docs.length) {
        const newDoc = doc(collectionReference);
        return setDoc(newDoc, Workspace.default(newDoc.id, user!).toJson('firestore'));
      }
      const workspaces = snap.docs.map(Workspace.fromDocument);
      flushSync(() => setWorkspaces(workspaces));
    });
  }, []);

  useEffect(() => {
    if (!workspaces.length) return;

    if (isCreatingNew.current) {
      selectWorkspace(workspaces[workspaces.length - 1].id);
      isCreatingNew.current = false;
      return;
    }

    const savedWorkspaceId = localStorage.getItem(StorageKeys.WorkspaceId);
    if (savedWorkspaceId) {
      selectWorkspace(savedWorkspaceId);
      return;
    }

    selectWorkspace(workspaces[0].id);
  }, [workspaces]);

  useEffect(() => {
    setMyWorkspaces(workspaces.filter((ws) => ws.isOwner));
    setParticipatedWorkspaces(workspaces.filter((ws) => !ws.isOwner));
  }, [workspaces]);

  // useEffect(() => {
  //   if (!currentWorkspace) return;
  //   toast({
  //     status: 'info',
  //     title: t('toast.selectedWorkspace'),
  //     description: `${currentWorkspace.name} ${currentWorkspace.icon || ''}`,
  //   });
  // }, [currentWorkspace]);

  const selectWorkspace = useCallback(
    (id: string) => {
      const newWorkspace = workspaces.find((ws) => ws.id == id) ?? workspaces[0];
      setCurrentWorkspace(newWorkspace);
      localStorage.setItem(StorageKeys.WorkspaceId, newWorkspace.id);
    },
    [workspaces],
  );

  const createWorkspace = useCallback(
    async (name: string, users: string[] = []) => {
      name = name.trim();
      const existing = await getDocs(query(collectionReference, where('name', '==', name)));
      if (existing.docs.length) {
        return t('toast.duplicateWorkspace');
      }

      const newDoc = doc(collectionReference);

      isCreatingNew.current = true;
      const newWorkspace = new Workspace({
        id: newDoc.id,
        name,
        owner: user!.uid,
        ownerName: user!.displayName!,
      });
      if (users.length) {
        newWorkspace.isShared = true;
        newWorkspace.users = users;
        const response = await api('/api/workspaces/shared/create', user).post(newWorkspace.toJson('server'));
        if (!response.ok) {
          toast({ status: 'error', title: t('_toast.genericError') });
          return;
        }
      }

      await setDoc(newDoc, newWorkspace.toJson('firestore'));
      toast({ status: 'success', title: t('_toast.genericSuccess') });
    },
    [collectionReference],
  );

  const renameWorkspace = useCallback(
    async (id: string, name: string) => {
      name = name.trim();
      const workspace = workspaces.find((ws) => ws.id == id);
      if (workspace?.isShared) {
        const response = await api('/api/workspaces/shared/rename', user).patch({ id: workspace.id, name });
        if (!response.ok) {
          toast({ status: 'error', title: t('_toast.genericError') });
          return;
        }
      }
      const docReference = doc(firestore, `users/${user?.uid}/workspaces/${id}`);
      await setDoc(docReference, { name }, { merge: true });
      toast({ status: 'success', title: t('_toast.genericSuccess') });
    },
    [workspaces, user?.uid],
  );

  const deleteWorkspace = useCallback(
    async (id: string) => {
      setLoading(true);
      const defaultWorkspace = workspaces.find((ws) => ws.isDefault)!.id;
      const [payments, cupboard] = await Promise.all([
        getDocs(query(collection(firestore, 'payments'), where('__workspace', '==', id))),
        getDocs(query(collection(firestore, 'cupboard'), where('__workspace', '==', id))),
      ]);
      const documents = [...payments.docs, ...cupboard.docs];
      const batchSize = 500;
      const chunks = chunk(documents, batchSize);
      for (const chunk of chunks) {
        const batch = writeBatch(firestore);
        for (const document of chunk) {
          batch.set(document.ref, { __workspace: defaultWorkspace }, { merge: true });
        }
        await batch.commit();
      }
      await deleteDoc(doc(firestore, `users/${user?.uid}/workspaces/${id}`));
      setLoading(false);
    },
    [workspaces, user?.uid],
  );

  const leaveWorkspace = useCallback(
    async (id: string) => {
      const response = await api('/api/workspaces/shared/leave', user).post({ id });
      if (response.ok) {
        toast({ status: 'success', title: t('_toast.genericSuccess') });
        const docReference = doc(firestore, `users/${user?.uid}/workspaces/${id}`);
        await deleteDoc(docReference);
      } else toast({ status: 'error', title: t('_toast.genericError') });
    },
    [workspaces, user?.uid],
  );

  const stopWorkspaceShare = useCallback(
    async (id: string) => {
      const response = await api('/api/workspaces/shared/stop-share', user).post({ id });
      if (response.ok) toast({ status: 'success', title: t('_toast.genericSuccess') });
      else toast({ status: 'error', title: t('_toast.genericError') });
    },
    [workspaces, user?.uid],
  );

  const changeWorkspaceIcon = useCallback(
    async (id: string, icon: string | null) => {
      const workspace = workspaces.find((ws) => ws.id == id);
      if (workspace?.isShared) {
        const response = await api('/api/workspaces/shared/change-icon', user).patch({ id: workspace.id, icon });
        if (!response.ok) {
          toast({ status: 'error', title: t('_toast.genericError') });
          return;
        }
      }
      const docReference = doc(firestore, `users/${user?.uid}/workspaces/${id}`);
      await setDoc(docReference, { icon }, { merge: true });
      toast({ status: 'success', title: t('_toast.genericSuccess') });
    },
    [workspaces, user?.uid],
  );

  const updateItemWorkspace = useCallback(async (itemPath: string, workspaceId: string) => {
    const docReference = doc(firestore, itemPath);
    await setDoc(docReference, { __workspace: workspaceId }, { merge: true });
    toast({ status: 'success', title: t('_toast.genericSuccess') });
  }, []);

  const value: IWorkspacesContext = {
    WorkspaceIcon,
    workspaces,
    myWorkspaces,
    participatedWorkspaces,
    currentWorkspace: currentWorkspace!,
    selectWorkspace,
    createWorkspace,
    renameWorkspace,
    deleteWorkspace,
    leaveWorkspace,
    stopWorkspaceShare,
    changeWorkspaceIcon,
    updateItemWorkspace,
  };

  if ((isLoggedIn && !currentWorkspace) || loading) return <Loader fullscreen={true} label={'WorkspacesProvider'} />;

  return <WorkspacesContext.Provider value={value}>{children}</WorkspacesContext.Provider>;
}
