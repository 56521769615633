import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';
import Loader from '@/components/structural/Loader';
import get from 'lodash.get';

type TranslationNode = {
  [key: string]: string | TranslationNode;
};

type ITranslationContext = {
  instant: (namespace: string) => (key: string, scope?: Record<string, string | number>) => string;
};

const TranslationContext = createContext<ITranslationContext>(null as any);

export const useTranslation = () => useContext(TranslationContext);

export const translate = (namespace: string) => {
  return useTranslation().instant(namespace);
};

const placeholders = (text: string, scope: Record<string, string | number>) => {
  return text.replace(/{([a-zA-Z]+)}/g, ($0, $1) => {
    return (scope[$1] ?? $0).toString();
  });
};

export default function TranslationProvider({ children }: PropsWithChildren) {
  const [messages, setMessages] = useState<TranslationNode>();
  const defaultLanguage = { locale: 'it-IT', short: 'it' };

  useEffect(() => {
    const lang = localStorage.getItem('language') || navigator.language;
    import(`../i18n/${lang}`)
      .catch(() => import(`../i18n/${defaultLanguage.locale}`))
      .then((module) => module.default)
      .then(setMessages);
  }, []);

  const instant = useCallback(
    (namespace: string) => {
      return (key: string, scope: Record<string, string | number> = {}) => {
        const rawValue = get(messages![namespace] ?? {}, key);
        const ifInvalidValue = `{MISSING;${key}}`;
        if (!rawValue) {
          const tryCommons = get(messages!['_commons'], key);
          if (tryCommons && typeof tryCommons == 'string') return tryCommons;
          return ifInvalidValue;
        }
        if (typeof rawValue != 'string') return ifInvalidValue;
        return placeholders(rawValue, {
          appName: process.env.NEXT_PUBLIC_APP_NAME!,
          proName: process.env.NEXT_PUBLIC_PRO_NAME!,
          ...scope,
        });
      };
    },
    [messages],
  );

  const value: ITranslationContext = { instant };

  if (!messages) return <Loader fullscreen={true} label={'TranslationProvider'} />;

  return <TranslationContext.Provider value={value}>{children}</TranslationContext.Provider>;
}
