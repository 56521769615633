import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from '@/context/AuthContext';
import Loader from '@/components/structural/Loader';
import { doc, onSnapshot, setDoc } from '@firebase/firestore';
import { firestore } from '@/firebase/client';
import { useCustomTheme } from '@/context/CustomThemeContext';
import { translate } from '@/context/TranslationContext';

export type BackgroundImageProvider = '' | 'apod' | 'bing' | 'lorempicsum' | 'unsplash' | 'pexels';
export type NewsTicker = 'rai' | 'ansa' | 'adnkronos' | 'ilsole24ore';
export type ColorScheme = 'orange' | 'yellow' | 'green' | 'teal' | 'blue' | 'cyan' | 'purple' | 'pink';
export type Language = 'it-IT';

export interface ISettings {
  backgroundImageProvider: BackgroundImageProvider;
  newsTicker: NewsTicker;
  colorScheme: ColorScheme;
  language: Language;
}

interface ISettingsContext extends ISettings {
  settings: ISettings;
  setSetting: <T extends keyof ISettings>(setting: T, value?: ISettings[T]) => Promise<void>;
}

const SettingsContext = createContext({} as ISettingsContext);

export const useSettings = () => useContext(SettingsContext);

export default function SettingsProvider({ children }: PropsWithChildren) {
  const { user, isLoggedIn } = useAuth();
  const [settings, setSettings] = useState<ISettings>();
  const docReference = useMemo(() => doc(firestore, `users/${user?.uid}`), [user?.uid]);
  const { toast } = useCustomTheme();
  const t = translate('SettingsProvider');

  const defaultSettings: ISettings = useMemo(
    () => ({
      backgroundImageProvider: 'apod',
      newsTicker: 'rai',
      colorScheme: 'teal',
      language: 'it-IT',
    }),
    [],
  );

  useEffect(() => {
    if (!isLoggedIn) return;
    return onSnapshot(docReference, (snap) => {
      if (snap.metadata.fromCache) return;
      if (!snap.data()?.settings) {
        setDoc(docReference, { settings: defaultSettings }, { merge: true });
        return;
      }
      setSettings({ ...defaultSettings, ...(snap.data()!.settings as ISettings) });
    });
  }, [isLoggedIn, docReference]);

  const setSetting = useCallback(
    <T extends keyof ISettings>(key: T, value?: ISettings[T]) => {
      if (!isLoggedIn) return Promise.resolve();
      return setDoc(docReference, { settings: { [key]: value ?? null } }, { merge: true }).then(() => {
        toast({ status: 'success', title: t('settingsUpdated') });
      });
    },
    [isLoggedIn, docReference],
  );

  if (isLoggedIn && !settings) return <Loader fullscreen label={'SettingsProvider'} />;

  const value: ISettingsContext = { settings: settings!, setSetting, ...settings! };

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
}
