import { extendTheme, type ThemeConfig, withDefaultVariant } from '@chakra-ui/react';

const config: ThemeConfig = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const sizes = { prose: '120ch' };

const theme = extendTheme(
  { config, sizes },
  withDefaultVariant({ variant: 'filled', components: ['Input', 'Select', 'NumberInput', 'Textarea'] }),
  withDefaultVariant({ variant: 'left-accent', components: ['Alert'] }),
);

export default theme;
