import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import {
  FacebookAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
  linkWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as authSignOut,
  unlink,
  updateProfile,
  type User,
  type UserCredential,
  signInWithEmailLink,
  updatePassword,
} from 'firebase/auth';
import { goOffline, Unsubscribe } from 'firebase/database';
import { auth, database, firestore } from '@/firebase/client';
import Loader from '@/components/structural/Loader';
import { useCustomTheme } from '@/context/CustomThemeContext';
import { doc, onSnapshot, setDoc } from '@firebase/firestore';
import * as Sentry from '@sentry/nextjs';
import { translate } from '@/context/TranslationContext';
import { StorageKeys } from '@/utils/client';

interface IAuthContext {
  user: User | null;
  isLoggedIn: boolean;
  isPro: boolean;
  userData: { [p: string]: any };
  signIn: (email: string, password: string) => Promise<UserCredential | null>;
  signUpInvite: (email: string, password: string, displayName: string) => Promise<any>;
  signOut: () => Promise<void>;
  linkWithProvider: (providerId: string) => Promise<void>;
  unlinkProvider: (providerId: string) => Promise<void>;
  setDisplayName: (displayName: string) => Promise<void>;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const useAuth = () => useContext(AuthContext);

export default function AuthProvider({ children }: PropsWithChildren) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { toast } = useCustomTheme();
  const t = translate('AuthProvider');

  const [isPro, setIsPro] = useState<boolean>();
  const [userData, setUserData] = useState<{ [p: string]: any }>({});

  useEffect(() => {
    goOffline(database);

    // const presenceRef = ref(database, '.info/connected');
    // let rtdbUnsubscribe: Unsubscribe;
    let userDataUnsubscribe: Unsubscribe;

    const authStateUnsubscribe = auth.onAuthStateChanged((user) => {
      // rtdbUnsubscribe?.();

      const loggedIn = !!user?.uid;
      setLoading(false);
      setIsLoggedIn(loggedIn);
      setUser(user);

      if (loggedIn) {
        Sentry.setUser({ id: user.uid, email: user.email || undefined });
        userDataUnsubscribe = onSnapshot(doc(firestore, `users/${user!.uid}`), (snap) => {
          const { pro = false, remainingInvites } = snap.data() ?? {};
          if (remainingInvites == null) setDoc(snap.ref, { remainingInvites: 5 }, { merge: true }).catch(console.error);
          setIsPro(pro);
          setUserData(snap.data()!);
        });
        // goOnline(database);
        // rtdbUnsubscribe = onValue(presenceRef, (snap) => {
        //   setDoc(doc(firestore, `users/${user.uid}`), { online: increment(snap.val() ? 1 : -1) }, { merge: true });
        // });
      } else {
        userDataUnsubscribe?.();
        Sentry.setUser(null);
        setIsPro(undefined);
        // goOffline(database);
        localStorage.removeItem(StorageKeys.WorkspaceId);
      }
    });

    return () => {
      // rtdbUnsubscribe?.();
      authStateUnsubscribe();
    };
  }, []);

  const signIn = (email: string, password: string) => {
    setLoading(true);
    return signInWithEmailAndPassword(auth, email, password)
      .then((credentials) => {
        toast({ status: 'success', title: t('toast.login') });
        return credentials;
      })
      .catch((error) => {
        console.error(error);
        toast({ status: 'error', title: error.name, description: error.code });
        return null;
      })
      .finally(() => setLoading(false));
  };

  const signUpInvite = (email: string, password: string, displayName: string) => {
    return createUserWithEmailAndPassword(auth, email, password).then((credentials) => {
      return Promise.all([
        updateProfile(credentials.user, { displayName }),
        setDoc(doc(firestore, `users/${credentials.user.uid}`), { displayName }, { merge: true }),
      ]);
    });
  };

  const signOut = () => {
    setLoading(true);
    return authSignOut(auth)
      .then(() => {
        toast({ status: 'info', title: t('toast.logout') });
      })
      .finally(() => setLoading(false));
  };

  const runSafe = async (callback: () => Promise<void>) => {
    try {
      if (!isLoggedIn) return;
      await callback();
      toast({ status: 'success', title: t('_toast.genericSuccess') });
    } catch (error) {
      console.error(error);
      toast({ status: 'error', title: t('_toast.genericError') });
    }
  };

  const linkWithProvider = (providerId: string) => {
    return runSafe(async () => {
      const provider = (() => {
        switch (providerId) {
          case 'google.com':
            return new GoogleAuthProvider();
          case 'facebook.com':
            return new FacebookAuthProvider();
          case 'github.com':
            return new GithubAuthProvider();
          default:
            throw new Error(`INVALID_PROVIDER:${providerId}`);
        }
      })();
      await linkWithPopup(user!, provider);
    });
  };

  const unlinkProvider = (providerId: string) => {
    return runSafe(async () => {
      await unlink(user!, providerId);
    });
  };

  const setDisplayName = (displayName: string, reload = true) => {
    return runSafe(async () => {
      await Promise.all([
        updateProfile(user!, { displayName }),
        setDoc(doc(firestore, `users/${user!.uid}`), { displayName }, { merge: true }),
      ]);
      if (reload) window.location.reload();
    });
  };

  // const inviteUser = (email: string) => {
  //   return runSafe(async () => {
  //     await sendSignInLinkToEmail(auth, email, { url: 'https://localhost:3000/invite', handleCodeInApp: true });
  //   });
  // };

  if (loading || (isLoggedIn && isPro == null)) return <Loader fullscreen={true} label={'AuthProvider'} />;

  const value: IAuthContext = {
    user,
    isLoggedIn,
    isPro: isPro!,
    userData,
    signIn,
    signUpInvite,
    signOut,
    linkWithProvider,
    unlinkProvider,
    setDisplayName,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
