import { FC, PropsWithChildren } from 'react';
import CustomThemeProvider from '@/context/CustomThemeContext';
import AuthProvider from '@/context/AuthContext';
import TranslationProvider from '@/context/TranslationContext';
import RoutesProvider from '@/context/RoutesContext';
import WorkspacesProvider from '@/context/WorkspacesContext';
import SettingsProvider from '@/context/SettingsContext';

const combineComponents = (...components: FC[]): FC<any> => {
  return components.reduce(
    (AccumulatedComponents: FC<any>, CurrentComponent: FC<any>) => {
      return ({ children }: PropsWithChildren<any>): JSX.Element => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }: PropsWithChildren<any>) => <>{children}</>,
  );
};

const providers = [
  TranslationProvider,
  CustomThemeProvider,
  RoutesProvider,
  AuthProvider,
  SettingsProvider,
  WorkspacesProvider,
];
export const AppContextProvider = combineComponents(...providers);
