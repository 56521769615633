import { Box, Center, Flex, Spinner } from '@chakra-ui/react';

export default function Loader({ fullscreen = false, label = '' }) {
  if (fullscreen)
    return (
      <Center h={'100vh'}>
        <Flex alignItems={'center'} direction={'column'} justifyContent={'center'}>
          <Spinner size={'xl'} />
          {process.env.NEXT_PUBLIC_ENVIRONMENT != 'PRODUCTION' && label && <Box mt={3}>{label}</Box>}
        </Flex>
      </Center>
    );
  return (
    <Flex alignItems={'center'} justifyContent={'center'} m={8}>
      <Spinner size={'xl'} />
    </Flex>
  );
}
