import { AppProps } from 'next/app';
import NextNProgress from 'nextjs-progressbar';
import { useSettings } from '@/context/SettingsContext';

export default function Main({ Component, pageProps }: AppProps) {
  const { colorScheme } = useSettings();

  return (
    <>
      <NextNProgress color={colorScheme} />
      <Component {...pageProps} />
    </>
  );
}
