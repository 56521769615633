import { type QueryDocumentSnapshot } from '@firebase/firestore';
import type { User } from 'firebase/auth';

export const StorageKeys = {
  WorkspaceId: 'workspaceId',
} as const;

export const join = (...strings: string[]): string => strings.join(' ');

export const unwrap = (doc: QueryDocumentSnapshot) => ({ id: doc.id, ...doc.data() });

export const formatDateWorld = (date: Date) => {
  const y = date.getFullYear();
  const m = (date.getMonth() + 1).toString().padStart(2, '0');
  const d = date.getDate().toString().padStart(2, '0');
  return `${y}-${m}-${d}`;
};

export const parsePaymentDate = (ymd: string) => {
  const [y, m, d] = ymd.split('-');
  return `${d}/${m}/${y}`;
};

export const formatDateIT = (date: Date) => {
  const y = date.getFullYear();
  const m = (date.getMonth() + 1).toString().padStart(2, '0');
  const d = date.getDate().toString().padStart(2, '0');
  return `${d}/${m}/${y}`;
};

interface ApiInterface {
  get: () => Promise<Response>;
  post: (data?: Record<string, any>) => Promise<Response>;
  patch: (data?: Record<string, any>) => Promise<Response>;
}
type RequestBody = Record<string, any>;

export const api = (path: string, user?: User | null): ApiInterface => {
  const withHeaders = async (object: RequestInit): Promise<RequestInit> => {
    const headers: HeadersInit = {};
    if (user) {
      headers.authorization = await user.getIdToken(true);
      headers['x-uid'] = user.uid;
    }
    return { ...object, headers: { ...headers, ...object.headers } };
  };
  const body = (data: RequestBody) => JSON.stringify(data);

  return {
    async get() {
      return fetch(path, await withHeaders({ method: 'GET' }));
    },
    async post(data: RequestBody = {}) {
      return fetch(path, await withHeaders({ method: 'POST', body: body(data) }));
    },
    async patch(data: RequestBody = {}) {
      return fetch(path, await withHeaders({ method: 'PATCH', body: body(data) }));
    },
  };
};

export const chunk = <T>(array: T[], chunkSize: number) =>
  Array(Math.ceil(array.length / chunkSize))
    .fill(0)
    .map((_, index) => index * chunkSize)
    .map((begin) => array.slice(begin, begin + chunkSize));
