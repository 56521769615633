import { createContext, PropsWithChildren, useContext } from 'react';
import {
  Montserrat as DefaultFont,
  Maven_Pro as LogoFont,
  Playfair_Display as SerifFont,
  PT_Mono as MonospaceFont,
  Unbounded as ClockFont,
} from 'next/font/google';
import { NextFont } from 'next/dist/compiled/@next/font';
import { useToast } from '@chakra-ui/react';

const font = DefaultFont({ subsets: ['latin'] });
const logoFont = LogoFont({ subsets: ['latin'] });
const serifFont = SerifFont({ subsets: ['latin'] });
const monospaceFont = MonospaceFont({ subsets: ['latin'], weight: '400' });
const clockFont = ClockFont({ subsets: ['latin'], weight: '400' });

interface ICustomThemeContext {
  font: NextFont;
  logoFont: NextFont;
  serifFont: NextFont;
  monospaceFont: NextFont;
  clockFont: NextFont;
  toast: ReturnType<typeof useToast>;
}

const CustomThemeContext = createContext<ICustomThemeContext>({} as ICustomThemeContext);

export const useCustomTheme = () => useContext(CustomThemeContext);

export default function CustomThemeProvider({ children }: PropsWithChildren) {
  const toast = useToast({
    isClosable: true,
    variant: 'subtle',
  });

  const value: ICustomThemeContext = { font, logoFont, serifFont, monospaceFont, clockFont, toast };

  return (
    <CustomThemeContext.Provider value={value}>
      <main className={value.font.className} style={{ minHeight: '100dvh' }}>
        {children}
      </main>
    </CustomThemeContext.Provider>
  );
}
