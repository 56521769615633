import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import {
  FaCalendarWeek,
  FaCarrot,
  FaCog,
  FaMoneyBillWave,
  FaStickyNote,
  FaTachometerAlt,
  FaUser,
  FaUtensils,
  FaWeight,
} from 'react-icons/fa';
import { translate } from '@/context/TranslationContext';

interface Route {
  path: string;
  label: string;
  icon: JSX.Element;
  isNew?: boolean;
  isWip?: boolean;
}

interface IRoutesContext {
  routes: Route[];
  navbarRoutes: Route[];
  userMenuRoutes: Route[];
  getRoute: (path: string) => Route | undefined;
}

const RoutesContext = createContext({} as IRoutesContext);

export const useRoutes = () => useContext(RoutesContext);

export default function RoutesProvider({ children }: PropsWithChildren) {
  const t = translate('Routes');

  const navbarRoutes: Route[] = useMemo(
    () => [
      {
        path: '/app/dashboard',
        label: t('dashboard'),
        icon: <FaTachometerAlt />,
      },
      {
        path: '/app/notes',
        label: t('notes'),
        icon: <FaStickyNote />,
        isNew: true,
      },
      {
        path: '/app/cupboard',
        label: t('cupboard'),
        icon: <FaCarrot />,
      },
      {
        path: '/app/payments',
        label: t('payments'),
        icon: <FaMoneyBillWave />,
      },
      {
        path: '/app/recipes',
        label: t('recipes'),
        icon: <FaUtensils />,
        isWip: true,
      },
      {
        path: '/app/diet',
        label: t('diet'),
        icon: <FaWeight />,
        isWip: true,
      },
      {
        path: '/app/meal-planner',
        label: t('mealPlanner'),
        icon: <FaCalendarWeek />,
        isWip: true,
      },
    ],
    [],
  );

  const userMenuRoutes: Route[] = useMemo(
    () => [
      {
        path: '/app/profile',
        label: t('profile'),
        icon: <FaUser />,
      },
      {
        path: '/app/settings',
        label: t('settings'),
        icon: <FaCog />,
      },
    ],
    [],
  );

  const routes = useMemo(() => [...navbarRoutes, ...userMenuRoutes], [navbarRoutes, userMenuRoutes]);

  const getRoute = (path: string) => routes.find((route) => route.path == path);

  const value: IRoutesContext = { getRoute, routes, navbarRoutes, userMenuRoutes };

  return <RoutesContext.Provider value={value}>{children}</RoutesContext.Provider>;
}
